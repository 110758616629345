@use "./custom-material";
@use "./variables" as *;
@use "./drawer";
@use "./menu";
@use "./login";
@use "./table";
@use "./nav";
@use "./dropdown";
@use "./list-button";
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "./button";
@use "./input";
@use "./dialog";
@use "./select";
@use "./mat-card";
@use '@material/ripple/common';

@font-face {
  font-family: "KmReg";
  src: url("../assets/fonts/KantumruyPro-Regular.ttf");
}

@font-face {
  font-family: "KmMed";
  src: url("../assets/fonts/KantumruyPro-Medium.ttf");
}

@font-face {
  font-family: "KmSemiBold";
  src: url("../assets/fonts/KantumruyPro-SemiBold.ttf");
}

@font-face {
  font-family: "EngLight";
  src: url("../assets/fonts/Quicksand-Light.ttf");
}

@font-face {
  font-family: "EngMedium";
  src: url("../assets/fonts/Quicksand-Medium.ttf");
}

@font-face {
  font-family: "EngReg";
  src: url("../assets/fonts/Quicksand-Regular.ttf");
}

@font-face {
  font-family: "EngSemiBold";
  src: url("../assets/fonts/Quicksand-SemiBold.ttf");
}

@font-face {
  font-family: "EngBold";
  src: url("../assets/fonts/Quicksand-Bold.ttf");
}

@font-face {
  font-family: "LoraReg";
  src: url("../assets/fonts/Lora-Regular.ttf");
}

@font-face {
  font-family: "LoraBold";
  src: url("../assets/fonts/Lora-Bold.ttf");
}

* {
  font-family: "EngReg", "KmReg";
}

html,
body {
  height: 100vh;
  box-sizing: border-box !important;
  margin: 0;
  font-family: "EngReg", "KmReg" !important;
  position: relative;
}

// html {
//   box-sizing: border-box;
// }
*,
*:before,
*:after {
  box-sizing: inherit;
}

// .row {
//   display: block;
//   margin-bottom: 10px;
// }

.app-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #3333;
  top: 0;
  left: 0;
  background-image: url("/assets/imgs/loading.svg");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 999;
}

.background-white {
  background-color: #fff;
}

.content {
  border-radius: 10px;
  color: $default-gray;

  &::-webkit-scrollbar {
    width: 0.4em;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #136a8a65;
      border-radius: 10px;
    }
  }

  @media (max-width: 375px) {
    width: 94%;
    margin-left: 0;
  }

  overflow: hidden;
  //background-color: white;
}

.nav-padding {
  // margin: 10px 10px 0 10px;
  width: 100%;
  padding: $menu-drawer-margin $menu-drawer-margin 0 $menu-drawer-margin;

  &.sided {
    padding-left: calc(2 * #{$menu-drawer-margin});
  }
}

.content-margin {
  overflow-x: hidden;
  flex: 1;
  height: 100%;
  width: calc(100% - (3 * #{$menu-drawer-margin}));
  position: relative;
  padding: $menu-drawer-margin !important;
  overflow-y: auto;
  background-color: transparent !important;
  margin: $menu-drawer-margin calc(2 * #{$menu-drawer-margin});
}

:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.selectDefaultPrice {
  font-size: 14.5px;
}

.snackbar-custom {
  color: #fff;
  font-family: "EngBold", "KmReg";
}

.snackbar-valid-false {
  background-color: #eb5757;
}

.snackbar-valid-true {
  background-color: #16cd12;
}

.sub-content {
  font-family: "EngReg", "KmReg";
  color: $default-gray;
  height: fit-content;
  overflow: hidden;
}

.custom-fee-dialog {
  padding: 16px !important;
}

.position-justify-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: "EngSemiBold", "KmReg" !important;
  font-size: 1.2rem;
  color: #000;
  //margin: 20px 0;
  margin-bottom: 20px;

  @media screen and (max-width: 428px) {
    font-size: 1rem;
  }
}

.sub-title {
  font-family: "EngBold", "KmReg";
  font-size: 1rem;
  font-weight: 500;
  color: $color-primary;
  margin-bottom: 20px;

  @media screen and (max-width: 428px) {
    font-size: 0.8rem;
  }
}

.field-content {
  color: $color-primary;
  font-family: "EngBold", "KmReg";
  font-weight: 500;

  span {
    font-family: "EngBold", "KmReg";
    color: $default-gray;
  }
}

.custom-date-label {
  align-self: flex-start;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.flex-col {
  flex-direction: column;
}

.display-flex {
  display: flex;
  align-items: center;
}

.file-size {
  border-radius: 10px;
  width: 156px;
  height: 156px;
}

.attachment-preview {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e8e9ed;
  width: 156px;
  height: 156px;
  position: relative;
  text-align: center;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    z-index: 1;
  }

  .attachment-content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .img-size {
      width: 40px;
    }

    .text-name {
      text-align: center;
      font-family: "EngSemiBold", "KmReg";
      color: #000;
      white-space: nowrap;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 5px;
    }

    .text-size {
      color: $default-gray;
      font-weight: 500;
    }
  }
}

.form-field {
  width: 100%;

  .full-width {
    width: 100%;

    ::ng-deep .mat-select-arrow {
      border: none !important;
    }

    .input-display {
      display: flex;
      align-items: center;

      .unit-size {
        font-size: 14px;
      }
    }
  }

  input {
    color: #000;
    font-family: "EngReg", "KmReg";
    font-weight: bold;
  }
}

.position-button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
  font-family: "EngSemiBold", "KmReg";
}

.custom-mat-select {
  width: 150px !important;
  border-radius: 10px;
  padding: 10px;
  background: #f6f6f6;
  text-align: center;
}

.mat-option .mat-option-text {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.custom-field {
  padding: 8px 10px;
  border-radius: 10px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  color: #bdbdbd;
}

.custom-b-border {
  margin-bottom: 27px;
  border-bottom: #8e8e8e 1px solid;
}

.custom-field-mod {
  padding: 4px 16px;
  border-radius: 4px 4px 0 0;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  color: #bdbdbd;
  margin-top: 6px;
}

.custom-field-mod:hover {
  background-color: #e4e4e4;
}

.custom-field-smaller {
  padding: 0 4px;
  border-radius: 10px;
  background: #f6f6f6;
  width: 180px;
  height: 58px;
  align-items: center;
  color: #bdbdbd;
}

.custom-input {
  border: none;
  background: none;
  width: 100%;
  font-family: "EngReg", "KmReg" !important;
}

.custom-input:focus-visible {
  outline: 0;
}

.btn-reload {
  background-color: #f6f6f6;
  color: #666666;
  border: 1px solid #f3f3f3;
  min-height: 40px;
  min-width: 40px;
  border-radius: 10px;
  width: fit-content !important;
  height: fit-content !important;
}

.btn-reload-bigger {
  background-color: #f6f6f6;
  color: #666666;
  border: 1px solid #f3f3f3;
  min-height: 58px;
  min-width: 58px;
  border-radius: 10px;
  width: fit-content !important;
  height: fit-content !important;
}

.empty-space {
  margin-bottom: 22px;
}

.btn-reload-bigger-mod {
  background-color: #f6f6f6;
  color: #666666;
  border: 1px solid #f3f3f3;
  min-height: 58px;
  min-width: 58px;
  border-radius: 10px;
  width: fit-content !important;
  height: fit-content !important;
}

.mat-raised-button {
  width: fit-content;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  font-family: "EngMedium", "KmReg";
}

.custom-input:focus-visible {
  outline: 0;
}

.font-semibold {
  font-family: "EngSemiBold", "KmReg";
}

.logout-menu {
  --mat-menu-item-label-text-font: "EngReg", "KmReg";
}

.mat-menu-panel {
  max-width: none !important;
  min-width: 180px !important;
  border-radius: 10px !important;

  &::-webkit-scrollbar {
    width: 0.4em;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #136a8a65;
      border-radius: 10px;
    }
  }

  #priceRange {
    .mat-menu-panel {
      background-color: red;
    }
  }

  &.print-menu {
    min-width: 264px !important;
  }

  &.logout-menu {
    min-width: 264px !important;
  }

  &.notification {
    max-width: 420px !important;
    max-height: 520px !important;

    .padding-box-noti {
      padding: 10px 26px 0 26px;
    }

    .mark-read-all {
      font-size: 12px;
      cursor: pointer;
    }

    .title-notifi {
      font-family: "EngBold", "KmReg";
      font-size: 16px;
    }
  }

  hr {
    width: 80% !important;
    margin: 0 0 0 auto !important;
    background-color: #f3f5f7;
    opacity: 1;
  }

  .mat-menu-item {
    font-family: "EngBold", "KmReg";
    color: $color-primary;
    font-size: 11pt;

    &.account-menu-item {
      font-size: 10pt;
      font-family: "EngReg", "KmReg" !important;
      color: #4f4f4f !important;
      border-bottom: none !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-start !important;
      align-items: center !important;

      &.close {
        color: $default-red !important;
      }
    }

    &.notifi-content {
      display: flex;
      align-items: center;
      line-height: normal !important;
      padding: 26px 26px 10px 26px !important;
      height: auto !important;
      white-space: pre-line !important;
      border-bottom: none;

      .profile-img {
        border-radius: 3rem;
        width: 52px;
        height: 52px;
        margin-right: 10px;
      }

      .text-notifi {
        color: black;
        font-family: "EngReg", "KmReg";
        font-size: 14px;

        .time {
          color: $color-primary;
          margin-top: 8px;
        }
      }

      &:not(:last-child) {
        border-bottom: none;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #f3f5f7;
    }
  }
}

.custom-daterange-picker {
  font-family: "EngReg", "KmReg";
  min-width: 180px;
  width: fit-content;
  gap: 5px;

  .ranges {
    li {
      border-radius: 5px !important;
      font-family: "EngReg", "KmReg" !important;
    }

    button {
      border-radius: 5px !important;
      font-family: "EngReg", "KmReg" !important;
    }

    .active {
      background-color: $color-primary !important;
    }
  }

  .calendar {
    .active {
      background-color: $color-primary;
      border-radius: 5px !important;

      &:hover {
        background-color: $color-primary;
      }
    }
  }

  .buttons_input {
    .btn {
      border-radius: 5px;
      box-shadow: none;
      background-color: $color-primary;
      text-transform: capitalize !important;
      padding: 0 15px;

      &:hover {
        background-color: $color-primary !important;
      }
    }

    .clear {
      margin-right: 10px;
      border: 1px solid $border-color;
      font-size: 1rem;
      color: #475569 !important;

      &:hover {
        background-color: white !important;
      }
    }

    svg {
      display: none !important;
    }
  }
}

input,
textarea {
  outline: none;
}

.no-data {
  font-family: "EngBold", "KmReg";
  font-size: 16px;
  font-weight: 500;
  color: #d1d1d1;
  display: flex;
  align-items: center;
}

.hide {
  display: none;
}

.mat-dialog-content {
  overflow-y: auto;
  list-style: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mat-dialog-content::-webkit-scrollbar {
  display: none;
}

.mat-header-cell {
  font-size: 14px !important;
  font-weight: 600;
}

.mat-row .mat-cell {
  vertical-align: middle !important;
  font-size: 14px !important;
}

.backdrop {
  background: rgba(0, 0, 0, 0.28) !important;
}

.base-button {
  margin-bottom: 0 !important;
  width: fit-content !important;
  border: none !important;
  background-color: #f6f6f6 !important;

  &:hover {
    color: #000 !important;
  }
}

.mat-step-header {
  pointer-events: none !important;
}

.login-container {
  display: flex;
  height: 100%;
  font-family: "EngReg";

  .left {
    background: url("/assets/imgs/img.png");
    width: 47%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .text {
      width: 70%;
      background: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(7px);
      padding: 56px 60px;
      border-radius: 1.5rem;
      color: #fff;
      margin-bottom: 40px;

      .text-title {
        font-family: "EngBold", "KmReg";
        font-size: 32px;
        margin-bottom: 40px;
        line-height: 1;
      }

      .text-sub {
        font-size: 18px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53%;
    height: 100%;
    margin: auto;
    margin-top: 30px;

    .right-c {
      width: 50%;
    }

    .logo-bontub {
      display: flex;
      align-items: center;

      .b {
        padding: 7px;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background: #d9d9d9;
        font-size: 40px;
        font-weight: 500;
        display: flex;
        justify-content: center;

        .b-background {
          background: url("/assets/imgs/logo_B.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 15px;
          background-position: center;
        }
      }

      .bontub {
        padding-left: 0px;
        width: 86px;
      }
    }

    .login {
      font-family: "EngBold", "KmReg";
      font-size: 2rem;
      color: #000;
      margin: 50px 0;
    }

    .login-sub {
      font-family: "EngMedium", "KmReg";
      margin-bottom: 40px;
    }
  }

  form {
    width: 300px;

    .mat-form-field {
      width: 100%;
    }
    .custom-formfield {
      margin-top: 16px !important;
    }

    mat-card-title,
    mat-card-content {
      display: flex;
      justify-content: center;
    }

    .error {
      padding: 14px;
      color: white;
      background-color: red;
      border-radius: 0.5rem;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    .button {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;

      .button-login {
        font-family: "EngReg";
        width: 100%;
        height: 50px;
        border-radius: 10px;
        font-size: 18px;
      }
    }
  }
}

.password {
  position: relative;

  .input-password {
    position: absolute;
  }

  .icon {
    position: absolute;
    right: 15px;
    transform: translateY(50%);
  }
}

.button {
  display: flex;
  justify-content: flex-end;
}

.base-input {
  width: 100%;
  padding: 15px 10px;
  border: 1px solid rgba(232, 233, 237, 1);
  border-radius: 0.5rem;
  background: rgba(249, 249, 249, 1);

  &:focus {
    outline: none;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
  }
}

@media screen and (max-width: 810px) {
  .login-container {
    background: url("/assets/imgs/img.png");
    background-size: cover;
    background-position: bottom;

    .left {
      display: none;
    }

    .right {
      background: rgba(255, 255, 255, 0.651);
      backdrop-filter: blur(7px);
      width: 100%;

      .right-c {
        width: 70%;
      }
    }

    form {
      .button {
        .button-login {
          font-family: "EngReg";
          padding: 5px 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 428px) {
  .login-container {
    .right {
      .right-c {
        width: 85%;

        .login-sub {
          font-size: 13px;
        }
      }
    }
  }
}

.custom {
  .mat-mdc-dialog-container {
    height: auto;
    .mdc-dialog__surface {
      border-radius: 20px !important;
    }

    .mat-mdc-dialog-title {
      padding: 10px 20px !important;
      font-family: "EngReg", "KmReg" !important;
    }

    .mat-mdc-dialog-content {
      padding-top: 10px !important;
      &::-webkit-scrollbar {
        // width: 0.4em;
        width: 0;
        height: 5px;
      }

      &:hover {
        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #86858565;
          border-radius: 10px;
        }
      }
      .custom-fee-dialog {
        padding: 16px !important;
      }
    }
  }

  .mat-mdc-dialog-actions {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-bottom: 20px !important;
  }
}

.custom-mat-card-title {
  font-size: 15px !important;
}
.custom-mat-card {
  padding: 10px !important;
}
.custom-mat-subtitle {
  color: rgb(82, 81, 81) !important;
  font-size: 15px !important;
}
.custom-last-updated {
  color: rgb(149, 148, 148) !important;
  font-size: 14px !important;
  margin-right: 20px !important;
}

@import "../styles/variables";

mat-nav-list.list-horizontal {
  padding: 0;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .mat-mdc-list-item {
    height: 40px;
    width: fit-content;
    border-radius: 3rem;
    text-align: center;
    justify-items: center;
    margin-right: 10px;

    .mdc-list-item__content {
      .mdc-list-item__primary-text {
        color: $default-gray;
        font-family: "EngReg", "KmReg" !important;
      }
    }

    &:hover {
      background: $color-primary;

      .mdc-list-item__content {
        .mdc-list-item__primary-text {
          color: #ffffff !important;
        }
      }
    }

    // .mdc-list-item-content {
    //   justify-content: center;
    //   overflow: hidden;

    //   &:hover {
    //     background: $color-primary;
    //     border-radius: 3rem;
    //   }
    // }
  }

  .list-item-active {
    background: $color-primary;
    border-radius: 3rem;

    .mdc-list-item__content {
      .mdc-list-item__primary-text {
        color: #ffffff !important;
      }
    }
  }

  .decrease-size-icon{
    font-size: 18px !important;
  }

  @media (max-width: 992px) {
    display: flex;
    overflow-x: auto;
    width: 100%;
    margin-bottom: 10px;

    .mat-mdc-list-item {
      width: 100%;
      margin: 5px 5px 15px 5px;
    }
  }
}

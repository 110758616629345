@import "../styles/variables";

// table.table {
//   font-family: Arial, Helvetica, sans-serif;
//   border-collapse: collapse;
//   width: 100%;

//   td,
//   th {
//     border: 1px solid #ddd;
//     padding: 8px;

//     .mat-button-base {
//       margin-right: 8px
//     }
//   }

//   tr {
//     &:nth-child(even) {
//       background-color: #f2f2f2;
//     }

//     &:hover {
//       background-color: #ddd;
//     }
//   }

//   th {
//     padding-top: 12px;
//     padding-bottom: 12px;
//     text-align: left;
//     background-color: #04AA6D;
//     color: white;
//   }
// }

// my table
.custom-table {
  width: 100%;
  text-align: center;

  th {
    font-weight: bold;
    color: #003c53 !important;
  }
}

.table-container {
  height: calc(100vh - 310px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4em;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #136a8a65;
      border-radius: 10px;
    }
  }
}

.mat-header-row .mat-header-cell {
  border-bottom: 1px solid transparent;
  color: $color-primary;
  background-color: #f8fbfc;
  font-size: 14px;
  font-family: "EngSemiBold", "KmReg" !important;
}

.mat-row .mat-cell {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  font-family: "EngReg", "KmReg" !important;
  vertical-align: sub;
}

.flex-container {
  display: flex;
  align-items: center !important;
}

.delete-color {
  color: #eb5757;
}

.view-color {
  color: $color-primary;
}

.pencil {
  color: #ffc727;
  width: 18px !important;
}

.print {
  color: #00bcd4;
  width: 18px !important;
}

.status-content {
  display: flex;
  align-items: center;
}

.status-active {
  font-family: "EngSemiBold", "KmReg" !important;
  color: #3cc13b;
}

.active-icon {
  width: 20px !important;
}

.active-timer-icon {
  width: 22px !important;
}

.active-timer {
  color: #eb5757;
  font-family: "EngSemiBold", "KmReg";
}

.font-semibold {
  font-family: "EngSemiBold", "KmReg";
}

.amount-contract {
  color: $default-gray;
}

.mat-column-action {
  width: 100px;
}

.mat-column-unit {
  width: 80px;
}

.danger-text {
  color: $default-gray;
  font-family: "EngSemiBold", "KmReg";
}

// .mat-row:hover .mat-cell {

//   border-color: red;
// }
@media screen and (max-width: 960px) {
  .mat-table {
    border: 0;
    vertical-align: middle;
  }

  .mat-table caption {
    font-size: 1em;
  }

  .mat-table .mat-header-row {
    display: none;
  }

  // Enable this to hide header
  .mat-table .mat-header-cell {
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .mat-table .mat-row {
    border-bottom: 1px solid #ddd;
    // display: block;
  }

  /*
    .mat-table .mat-row:nth-child(even) {background: #CCC}
    .mat-table .mat-row:nth-child(odd) {background: #FFF}
    */
  .mat-table .mat-cell {
    // border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    text-align: right;
    font-weight: bold;
    height: 50px;
  }

  .mat-table .mat-cell:nth-child(4) {
    width: 100%;
  }

  .mat-table .mat-cell:nth-child(5) {
    width: 100%;
  }

  .mat-table .mat-cell:before {
    /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
    content: attr(data-label);
    color: black;
    float: left;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.85em;
  }

  .mat-table .mat-cell:last-child {
    border-bottom: 0;
    padding-right: 0;
    padding-bottom: 10px;
  }

  .mat-table .mat-cell:first-child {
    margin-top: 4%;
    padding-left: 0;
  }

  .mat-column-action {
    width: 100% !important;
  }
}

.button-container {
  box-shadow: none !important;
  border: 1px solid #f2f2f2 !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.delete-color {
  color: #eb5757;
}

.pencil {
  color: #ffc727;
  width: 18px !important;
}

.print {
  color: #00bcd4;
  width: 18px !important;
}

.status-content {
  display: flex;
  align-items: center;
}

.status-active {
  font-family: "EngSemiBold", "KmReg";
  color: #3cc13b;
}

.active-icon {
  width: 20px !important;
}

.active-timer-icon {
  width: 22px !important;
}

.active-timer {
  color: #eb5757;
  font-family: "EngSemiBold", "KmReg";
}

.font-semibold {
  font-family: "EngSemiBold", "KmReg";
}

.mat-column-action {
  width: 100px;
}

.mat-column-unit {
  width: auto;
  min-width: 80px;
}

$color-primary: #003c53;
$default-bg: #f9fafb;
$border-color: #f3f3f3;
$input-bg: #f9f9f9;
$default-red: #e9565a;
$default-green: #16cd12;
$default-gray: #484848;

// dimension......
$menu-width: 240px;
$menu-drawer-margin: 10px;
$breakpoint: 1099px;
//colors
$primary-color: #2661a7;
$accent-color: #e1a83e;
$default-font-size: 14px;
$default-border-radius: 10px;
$label-color: #72777a;
$error-color: #ff5c5c;

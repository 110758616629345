@import "../styles/variables";

.select {
  width: 200px;
  border-radius: 5em;
  padding: 0.5em 0.5em;
  color: $default-gray;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  display: inline-flex;
  grid-template-areas: "select";
  align-items: center;
}

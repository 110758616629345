@use "./variables" as *;

.mat-drawer-container {
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
  position: relative;

  .mat-drawer {
    width: $menu-width !important;
    border-right: none !important;
    background-color: $color-primary !important;
    padding: 16px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
    margin: $menu-drawer-margin 0 $menu-drawer-margin $menu-drawer-margin !important;

    .mat-drawer-inner-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      position: relative;

      .logo-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 20px 0 30px 0;

        .img-box {
          height: 35px;
          width: 35px;
          overflow: hidden;
          border-radius: 5px;
          padding: 5px;
          background-color: white;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .img-in-text {
          max-height: 18px;
          margin-left: 10px;
        }
      }

      .mat-mdc-nav-list {
        flex: 1;
        width: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 5px;
        }

        &:hover {
          &::-webkit-scrollbar-track {
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #136a8a65;
            border-radius: 10px;
          }
        }

        .mat-expansion-panel {
          box-shadow: none !important;
          background: transparent !important;

          .mat-expansion-panel-header {
            padding: 0 10px 0 20px !important;
            height: 50px !important;

            .mat-expansion-panel-header-title {
              gap: 10px;

              .panel-title {
                font-size: 1rem !important;
              }
            }

            .mat-expansion-indicator {
              &::after {
                color: white !important;
              }
            }
          }

          .mat-expansion-panel-body {
            padding: 0 !important;
          }
        }

        .mat-mdc-list-item {
          font-size: 0.8rem;
          height: 50px !important;
          margin-bottom: 4px;
          border-radius: 10px;
          font-family: "";

          span {
            font-family: "EngReg", "KmReg" !important;
          }

          .mdc-list-item__primary-text {
            color: #ffffff !important;
            display: flex !important;
            flex-direction: row !important;
            justify-content: flex-start !important;
            align-items: center !important;
            gap: 10px;

            .mat-icon {
              width: 24px !important;
              height: 24px !important;
              text-align: center !important;

              &.custom-icon {
                width: 20px !important;
                height: 25px !important;
              }
            }

            .spacer {
              flex: 1;
            }

            .badge {
              background-color: #f9a825;
              margin: 0;
              border-radius: 10px;
              text-align: center;
              font-family: "EngReg", "KmReg";
              font-size: 0.7rem;
            }
          }

          &:hover {
            color: #f2f5f7 !important;
          }
        }

        .list-item-active {
          position: relative;
          background: rgba(255, 255, 255, 0.1) !important;
          border-radius: 0.8rem;
          color: #ffffff !important;
          font-family: "EngBold" !important;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 5px;
            background-color: white;
            height: 50%;
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
          }
        }
      }
    }
  }

  .mat-drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    box-sizing: border-box !important;
    position: relative !important;
  }
}

.logo {
  text-align: center;
  padding: 2.5rem 1rem;

  img {
    width: 200px;
  }
}

// mat-drawer {
//   margin: 10px;
//   width: 250px;
//   border-right: none;
//   color: white !important;
//   border-radius: 10px;
//   padding: 16px;

//   .mat-drawer-inner-container {
//     height: 100%;
//     width: 100%;
//     background-color: $color-primary;
//   }
// }

// mat-drawer-container {
//   height: 100%;
//   background-color: darken($color: $default-bg, $amount: 2) !important;
//   display: flex;
// }

// mat-drawer-content {
//   display: flex !important;
//   flex-direction: column;
// }

// .mat-nav-list .mat-list-item,
// .mat-action-list .mat-list-item,
// .mat-list-option {
//   overflow: auto;
// }

.default-input {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      background-color: #f9f9f9 !important;

      &.mdc-text-field--outlined {
        --mdc-outlined-text-field-container-shape: 10px !important;
      }

      .mat-mdc-form-field-icon-suffix {
        mat-icon {
          color: rgb(158, 157, 157) !important;
        }
      }
    }

    &.full-width {
      width: 100% !important;
    }
  }
}
.custom-input-date-picker {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@use "./variables" as *;

.mat-mdc-select {
  .mat-mdc-select-value-text {
    font-size: 16px !important;
  }

  .mat-mdc-select-placeholder {
    font-size: 16px !important;
  }
}

div.mat-mdc-select-panel {
  padding: 2px 7px !important;
  border-radius: 10px !important;
  &::-webkit-scrollbar {
    // width: 0.4em;
    width: 0;
    height: 5px;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #86858565;
      border-radius: 10px;
    }
  }

  .mat-mdc-option {
    font-size: 15px !important;
    font-family: "KmReg" !important;
    border-radius: 7px;
    margin: 5px 0 !important;

    .mat-pseudo-checkbox {
      display: none !important;
    }

    &.mdc-list-item--selected {
      background-color: rgb(230, 230, 230) !important;
    }
  }
}
.custom-ngx-select {
  margin-bottom: 10px;
}

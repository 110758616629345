@import "../styles/variables";

$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
);

.base-button {
  border-radius: 3rem !important;
  padding: 0 20px !important;
  margin-bottom: 10px !important;
  &.custom-back-button {
    border: 1px solid rgb(122, 120, 120) !important;
    a {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
      color: black;
      text-decoration: none;
    }
  }
}

.primary-button {
  border-radius: 0.6rem !important;
  box-shadow: none !important;
  width: 150px;

  &.expensetype-button,
  &.location-button,
  &.building-button,
  &.request-button,
  &.user-button,
  &.expense-button,
  &.room-notification,
  &.custom-mark-as-paid-btn,
  &.announcements-button {
    width: 200px;
    height: 45px !important;
  }

  &.expensetype-button-mod {
    height: 54px;
  }
  &.custom-mark-as-paid {
    background-color: $color-primary !important;
  }
  &.custom-create-organization-btn {
    width: 210px;
  }
  &.custom-others-save-button {
    width: 100px;
  }

  &.expense-button-mod,
  &.location-button-mod,
  &.building-button-mod,
  &.user-button-mod,
  &.request-button-mod,
  &.expensetype-button-mod,
  &.room-notification-mod {
    height: 50px;
    width: 250px;
    font-size: 17px;
  }
  &.announcements-button-mod {
    height: 54px;
    width: 250px;
  }
  &.monthly-report-mod {
    height: 54px;
    width: 150px;
  }
  &.newrecord-btn {
    width: 200px;
    height: 40px !important;
  }

  &.newrecord-btn-mod {
    height: 54px !important;
  }
  &.custom-create-record-unpaid {
    width: 200px;
    height: 50px !important;
  }
  &.announcement-btn-custom {
    height: 50px;
    font-size: 18px;
  }
}

/////////////
.outline-button {
  border-radius: 0.6rem !important;
  border: 1px solid $color-primary !important;
  color: $color-primary !important;
  box-shadow: none !important;
  width: 150px;
  // width: fit-content;
  &.unit-update-button {
    width: 200px;
  }
  &.custom-cancel-mark-as-unpaid {
    width: 200px;
    height: 45px !important;
    color: #00bcd4 !important;
    border: 1px solid #00bcd4 !important;
  }
  &.unit-update-button-mod {
    height: 54px;
    width: 200px;
  }
  &.announcement-btn-custom {
    height: 50px;
    font-size: 18px;
  }
  &.monthly-report-custom {
    height: 54px;
    width: 150px;
  }
}

.refresh-button {
  border: 1px solid #f3f3f3 !important;
  border-radius: 10px !important;
  padding: 0 !important;
  background-color: #fafafa;
  color: #666666;
}

@media screen and (max-width: 960px) {
  .primary-button {
    .display-title {
      display: none;
    }
  }
  .display-title {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .primary-button {
    width: 100%;
  }

  .outline-button {
    width: 100%;
  }

  .remove-flex {
    display: block !important;
  }
}

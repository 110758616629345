@use "@angular/material" as mat;

@include mat.core();

$custom-primary: (
  50: #e3edf1,
  100: #bad2dc,
  200: #8cb5c4,
  300: #5e97ac,
  400: #3c809b,
  // 500: #196a89,
  500: #003c53,
  600: #166281,
  700: #125776,
  800: #0e4d6c,
  900: #083c59,
  A100: #8cd0ff,
  A200: #59bbff,
  A400: #26a6ff,
  A700: #0d9cff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$custom-accent: (
  50: #fdf3ea,
  100: #fbe0cb,
  200: #f8cca8,
  300: #f5b785,
  400: #f2a76a,
  500: #f09850,
  600: #ee9049,
  700: #ec8540,
  800: #e97b37,
  900: #e56a27,
  A100: #ffffff,
  A200: #fff4ee,
  A400: #ffd1bb,
  A700: #ffc0a1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$custom-warn: (
  50: #fcebeb,
  100: #f8ccce,
  200: #f4abad,
  300: #f0898c,
  400: #ec6f73,
  500: #e9565a,
  600: #e64f52,
  700: #e34548,
  800: #df3c3f,
  900: #d92b2e,
  A100: #ffffff,
  A200: #ffe6e7,
  A400: #ffb3b4,
  A700: #ff9a9b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-init-primary: mat.define-palette($custom-primary);
$angular-init-accent: mat.define-palette($custom-accent, A200, A100, A400);
$angular-init-warn: mat.define-palette($custom-warn);

$angular-init-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-init-primary,
      accent: $angular-init-accent,
      warn: $angular-init-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-init-theme);

/* You can add global styles to this file, and also import other style files */
// .my-custom-color {
//   @include mat.all-component-themes($mycustom-init-theme);
//   @include mat.checkbox-theme($mycustom-init-theme);
// }

// html,
// body {
//   height: 100%;
// }
// body {
//   margin: 0;
//   font-family: EngReg, "Helvetica Neue", sans-serif;
// }
// .mat-form-field-infix {
//   border-top: 0.3em solid transparent !important;
// }
// .mat-form-field-appearance-outline .mat-form-field-infix {
//   padding: 0.8em 0 0.8em 0;
//   font-size: 1rem;
// }
// .mat-form-field {
//   font-family: "EngBold";
// }
// .mat-form-field-outline .mat-form-field-outline-start {
//   border-radius: 10px 0 0 10px !important;
//   min-width: 10px !important;
//   background: rgba(249, 249, 249, 1);
// }
// .mat-form-field-outline .mat-form-field-outline-end {
//   border-radius: 0 10px 10px 0 !important;
//   min-width: 10px !important;
//   background: rgba(249, 249, 249, 1);
// }
// .mat-form-field-appearance-outline .mat-form-field-flex {
//   padding: 0 1.5em 0 1.5em !important;
// }
// .mat-checkbox-inner-container {
//   width: 20px !important;
//   height: 20px !important;
// }
// .mat-checkbox-background,
// .mat-checkbox-frame {
//   border-radius: 5px !important;
// }
// .mat-checkbox-frame {
//   border-color: rgba(232, 233, 237, 1);
// }
// .mat-select-panel {
//   &::-webkit-scrollbar {
//     width: 0.4em;
//     background-color: rgba(0, 0, 0, 0.05);
//   }
//   &:hover {
//     &::-webkit-scrollbar-track {
//       border-radius: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//       background-color: #136a8a65;
//       border-radius: 10px;
//     }
//   }
// }
// .mat-select .mat-select-trigger .mat-select-arrow-wrapper,
// .mat-select-arrow {
//   width: 20px;
//   height: 10px;
//   border: 0px;
//   background-image: url("/assets/imgs/arrow-down.svg") !important;
//   background-repeat: no-repeat !important;
//   transform: scale(0.8);
//   margin: 0 4px;
//   border: none !important;
// }
// .mat-datepicker-content-touch {
//   max-height: 100vh !important;
// }
// .mat-date-range-input-container {
//   justify-content: flex-end;
// }
